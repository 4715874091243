.flip-button {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.flip-button button {
  border-radius: 17px;
  margin: 7px 3px;
}

.rotation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
}

.rotation-buttons button {
  width: 25%;
  margin: 7px 3px;
}
