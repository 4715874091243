@import url(https://fonts.googleapis.com/css?family=Playfair+Display&display=swap);
html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: #25252d;
  font-family: Arial, Helvetica, sans-serif;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100%;
  background-color: #25252d;
}

.wrapper {
  height: 100%;
  background-color: #25252d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  background-color: #1a1a20;
  color: #d42f2f;
  min-height: 60px;
  width: 100%;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.footer {
  background-color: #1a1a20;
  color: #fff;
  height: 69px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.side-menu {
  height: 100%;
  min-width: 300px;
  color: #d42f2f;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
}

.side-menu .icons {
  background-color: #1a1a20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 75px;
  margin-right: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.side-menu .options {
  background-color: #1a1a20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 225px;
  margin-right: auto;
  z-index: 99;
  border-left: solid 1px #3a3a3e;
}

.content {
  background-color: #25252d;
  height: 100%;
  width: 100%;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.middle-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
}

.MuiSlider-root {
  color: #d42f2f !important;
}

.desktop {
  height: 100vh;
  width: 100%;
}

.mobile {
  height: 100vh;
  width: 100%;
  display: none;
  cursor: default
}

.no-responsive-text {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 1100px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.header-icons {
  color: white;
  padding: 10px;
}

.header-icons:hover {
  color: #0099ff;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-container .left-side {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.header-container .left-side:first-child {
  margin-left: 17px;
}

.header-container .center-part {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-container .right-side {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.header-container .right-side button {
  margin: auto 7px;
  border-radius: 9px;
}

.header-container .right-side {
  margin-right: 15px;
}

.inside-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inside-wrapper button {
  margin: auto 7px;
}

.sidebar-icons {
  color: white;
  padding: 12px;
}

.sidebar-icons:hover {
color: #d42f2f;
}

.sidebar-icons .active {
color: #d42f2f;
}

#canvas-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crop-div {
  border: 3px solid white;
  z-index: 100;
  position: absolute;
}

.resize-section-wrapper {
  width: 175px;
}

.resize-section {
  display: flex;
  width: 100% !important;
}

.resize-section .left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #f1f1f1;
}

.resize-section .left-section label {
  margin-top: 14px;
}

.resize-section .right-section {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  padding: 0px 10px;
}

.resize-section .right-section input {
  width: 60px;
  margin-top: 7px;
}

.resize-section-wrapper button {
  float: right;
  margin: 5px 10px;
}

.flip-button {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.flip-button button {
  border-radius: 17px;
  margin: 7px 3px;
}

.rotation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
}

.rotation-buttons button {
  width: 25%;
  margin: 7px 3px;
}

.info-wrapper {
  display: flex;
}

.scale-slider-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.scale-slider-wrapper label {
  margin: auto 3px;
  color: #f1f1f1;
  min-width: 90px;
  padding: 7px 0px;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.footer-wrapper .left-panel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: #f1f1f1;
}

.footer-wrapper .left-panel label {
  margin: auto 7px;
}

.footer-wrapper .left-panel:first-child {
  margin-left: 15px;
}

.footer-wrapper .center-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-wrapper .right-panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #f1f1f1;
  font-size: 20px;
}

.footer-wrapper .right-panel:last-child {
  margin-right: 17px;
}

.crop-section-wrapper {
  width: 175px;
}

.crop-section {
  display: flex;
  width: 100% !important;
}

.crop-section .left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #f1f1f1;
}

.crop-section .left-section label {
  margin-top: 14px;
}

.crop-section .right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0px 10px;
}

.crop-section .right-section label {
  width: 60px;
  margin-top: 10px;
  color: #f1f1f1;
  font-size: 18.5px;
}

.crop-section-wrapper button {
  float: right;
  margin: 5px 10px;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: default !important;
}

.loading-container {
  width: 400px;
  cursor: default !important;
}
.wrapper {
  height: 100%;
  background-color: #25252d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upper-wrapper {
  background-color: #32323c;
  height: 50%;
  width: 75%;
  display: flex;
  align-items: center;
}

.upper-wrapper .panel-left {
  width: 25%;
  height: 60%;
  margin: 20px;
  display: flex;
}

.upper-wrapper .panel-left .content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: #f1f1f1;
  font-size: 18px;
  background-color: #ffffff;
  border-radius: 30px;
}
.upper-wrapper .panel-left .content .contentHeader {
  font-size: 21px;
  margin-bottom: 7px;
}
.upper-wrapper .panel-left .content .content-image-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.upper-wrapper .panel-right {
  min-width: 70%;
  height: 90%;
  min-height: 250px;
  max-height: 300px;
  border-radius: 30px;
  background-color: #333333;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.panel-right .header-for-right-panel {
  color: #f1f1f1;
  margin: 5px 25px;
  font-size: 25px;
  display: flex;
  justify-content: center;
}

.panel-right .row-flex-wrapper {
  display: flex;
  flex-direction: row;
}

.panel-right .image-wrapper {
  display: flex;
  flex-direction: column;
  height: 250px;
  width: 250px;
  display: flex;
  flex-direction: column;
}

.panel-right .image-box {
  background-color: #404040;
  height: 200px;
  margin: 10px 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.panel-right .image-box:hover {
  cursor: pointer;
}

.image-box .fas {
  position: absolute;
  right: 2px;
  top: 2px;
  color: white;
  font-size: 13px;

  display: inline-block;
  border-radius: 100px;
  box-shadow: 0px 0px 10px black;
  background-color: black;
  padding: 0.3em 0.4em;
}

.panel-right .image-desc {
  max-height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -10px;
  font-size: 16px;
  color: #f1f1f1;
  text-align: center;
}

.lower-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45%;
  width: 55%;
  min-height: 250px;
  margin: auto;
  z-index: 9;
}

.lower-wrapper .drag-drop-panel {
  background-color: #32323c;
  color: #ffffff;
  text-align: center;
  min-width: 100%;
  min-height: 100%;
  border: 2px dashed #707070;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

