.resize-section-wrapper {
  width: 175px;
}

.resize-section {
  display: flex;
  width: 100% !important;
}

.resize-section .left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #f1f1f1;
}

.resize-section .left-section label {
  margin-top: 14px;
}

.resize-section .right-section {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  padding: 0px 10px;
}

.resize-section .right-section input {
  width: 60px;
  margin-top: 7px;
}

.resize-section-wrapper button {
  float: right;
  margin: 5px 10px;
}
